exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-android-tsx": () => import("./../../../src/pages/android.tsx" /* webpackChunkName: "component---src-pages-android-tsx" */),
  "component---src-pages-avatars-tsx": () => import("./../../../src/pages/avatars.tsx" /* webpackChunkName: "component---src-pages-avatars-tsx" */),
  "component---src-pages-calculadora-tsx": () => import("./../../../src/pages/calculadora.tsx" /* webpackChunkName: "component---src-pages-calculadora-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-guide-tsx": () => import("./../../../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ios-tsx": () => import("./../../../src/pages/ios.tsx" /* webpackChunkName: "component---src-pages-ios-tsx" */),
  "component---src-pages-legal-earn-en-tsx": () => import("./../../../src/pages/legal/earn/en.tsx" /* webpackChunkName: "component---src-pages-legal-earn-en-tsx" */),
  "component---src-pages-legal-earn-es-tsx": () => import("./../../../src/pages/legal/earn/es.tsx" /* webpackChunkName: "component---src-pages-legal-earn-es-tsx" */),
  "component---src-pages-legal-earn-index-tsx": () => import("./../../../src/pages/legal/earn/index.tsx" /* webpackChunkName: "component---src-pages-legal-earn-index-tsx" */),
  "component---src-pages-legal-earn-pt-tsx": () => import("./../../../src/pages/legal/earn/pt.tsx" /* webpackChunkName: "component---src-pages-legal-earn-pt-tsx" */),
  "component---src-pages-legal-express-en-tsx": () => import("./../../../src/pages/legal/express/en.tsx" /* webpackChunkName: "component---src-pages-legal-express-en-tsx" */),
  "component---src-pages-legal-express-es-tsx": () => import("./../../../src/pages/legal/express/es.tsx" /* webpackChunkName: "component---src-pages-legal-express-es-tsx" */),
  "component---src-pages-legal-express-index-tsx": () => import("./../../../src/pages/legal/express/index.tsx" /* webpackChunkName: "component---src-pages-legal-express-index-tsx" */),
  "component---src-pages-legal-express-pt-tsx": () => import("./../../../src/pages/legal/express/pt.tsx" /* webpackChunkName: "component---src-pages-legal-express-pt-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */),
  "component---src-pages-legal-qr-payments-en-tsx": () => import("./../../../src/pages/legal/qr-payments/en.tsx" /* webpackChunkName: "component---src-pages-legal-qr-payments-en-tsx" */),
  "component---src-pages-legal-qr-payments-es-tsx": () => import("./../../../src/pages/legal/qr-payments/es.tsx" /* webpackChunkName: "component---src-pages-legal-qr-payments-es-tsx" */),
  "component---src-pages-legal-qr-payments-index-tsx": () => import("./../../../src/pages/legal/qr-payments/index.tsx" /* webpackChunkName: "component---src-pages-legal-qr-payments-index-tsx" */),
  "component---src-pages-legal-qr-payments-pt-tsx": () => import("./../../../src/pages/legal/qr-payments/pt.tsx" /* webpackChunkName: "component---src-pages-legal-qr-payments-pt-tsx" */),
  "component---src-pages-legal-score-en-tsx": () => import("./../../../src/pages/legal/score/en.tsx" /* webpackChunkName: "component---src-pages-legal-score-en-tsx" */),
  "component---src-pages-legal-score-es-tsx": () => import("./../../../src/pages/legal/score/es.tsx" /* webpackChunkName: "component---src-pages-legal-score-es-tsx" */),
  "component---src-pages-legal-score-index-tsx": () => import("./../../../src/pages/legal/score/index.tsx" /* webpackChunkName: "component---src-pages-legal-score-index-tsx" */),
  "component---src-pages-legal-score-pt-tsx": () => import("./../../../src/pages/legal/score/pt.tsx" /* webpackChunkName: "component---src-pages-legal-score-pt-tsx" */),
  "component---src-pages-legal-special-terms-es-tsx": () => import("./../../../src/pages/legal/special-terms/es.tsx" /* webpackChunkName: "component---src-pages-legal-special-terms-es-tsx" */),
  "component---src-pages-legal-special-terms-index-tsx": () => import("./../../../src/pages/legal/special-terms/index.tsx" /* webpackChunkName: "component---src-pages-legal-special-terms-index-tsx" */),
  "component---src-pages-legal-terms-and-conditions-en-tsx": () => import("./../../../src/pages/legal/terms-and-conditions/en.tsx" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-en-tsx" */),
  "component---src-pages-legal-terms-and-conditions-es-tsx": () => import("./../../../src/pages/legal/terms-and-conditions/es.tsx" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-es-tsx" */),
  "component---src-pages-legal-terms-and-conditions-index-tsx": () => import("./../../../src/pages/legal/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-index-tsx" */),
  "component---src-pages-legal-terms-and-conditions-pt-tsx": () => import("./../../../src/pages/legal/terms-and-conditions/pt.tsx" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-pt-tsx" */),
  "component---src-pages-ref-[id]-tsx": () => import("./../../../src/pages/ref/[id].tsx" /* webpackChunkName: "component---src-pages-ref-[id]-tsx" */),
  "component---src-pages-registro-[source]-tsx": () => import("./../../../src/pages/registro/[source].tsx" /* webpackChunkName: "component---src-pages-registro-[source]-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-swaps-tsx": () => import("./../../../src/pages/swaps.tsx" /* webpackChunkName: "component---src-pages-swaps-tsx" */),
  "component---src-pages-telegram-tsx": () => import("./../../../src/pages/telegram.tsx" /* webpackChunkName: "component---src-pages-telegram-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blogs-tsx": () => import("./../../../src/templates/blogs.tsx" /* webpackChunkName: "component---src-templates-blogs-tsx" */)
}

